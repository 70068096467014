export default function getButtonText({
  buttonText,
  isDatePast,
  willBeRecorded,
}) {
  if (isDatePast && willBeRecorded) {
    return "Access the recording";
  }

  if (buttonText) return buttonText;
  return "Register now";
}
