import capitaliseFirstLetter from "~utils/capitalise_first_letter/capitalise_first_letter";

export default function getEventTitle({
  eventType,
  isDatePast,
  willBeRecorded,
  title,
}) {
  if (isDatePast && willBeRecorded) {
    return `On-demand event: ${title}`;
  }

  const capitalisedEventType = capitaliseFirstLetter(eventType);

  return `${capitalisedEventType}: ${title}`;
}
