import React from "react";
import PropTypes from "prop-types";
import GridNew from "~components/atoms/grid_new/grid_new";
import HorizontalDivider from "~components/molecules/horizontal_divider/horizontal_divider";
import getGridSplit from "~utils/get_grid_split/get_grid_split";
import { BLOK_ARRAY } from "../../../prop_types";
import FeaturedPersonLandscape from "./components/featured_person_landscape/featured_person_landscape";
import { wrapperStyle } from "./featured_people_grid.module.scss";

export default function FeaturedPeopleGrid({ speakers, speakersTitle }) {
  const split = getGridSplit({ length: speakers.length });

  return (
    <div data-testid="featured-people-grid" className={wrapperStyle}>
      <HorizontalDivider text={speakersTitle} />

      <GridNew split={split}>
        {speakers.length > 0 &&
          speakers.map((item) => {
            return <FeaturedPersonLandscape key={item.name} {...item} />;
          })}
      </GridNew>
    </div>
  );
}

FeaturedPeopleGrid.propTypes = {
  speakers: BLOK_ARRAY,
  speakersTitle: PropTypes.string,
};

FeaturedPeopleGrid.defaultProps = {
  speakers: null,
  speakersTitle: null,
};
