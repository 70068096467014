export default function getFormSubtitle({
  formSubtitle,
  isDatePast,
  willBeRecorded,
}) {
  if (isDatePast && willBeRecorded) {
    return `Complete the form below to get access to the recording.`;
  }

  if (isDatePast) {
    return `Complete the form below to register your interest for upcoming events.`;
  }

  return formSubtitle;
}
