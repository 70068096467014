import getButtonText from "../get_button_text/get_button_text";
import getEventTitle from "../get_event_title/get_event_title";
import getFormSubtitle from "../get_form_subtitle/get_form_subtitle";
import getFormTitle from "../get_form_title/get_form_title";
import getSubtitleText from "../get_subtitle_text/get_subtitle_text";

export default function getLandingPageStrings({
  buttonText,
  date,
  eventType,
  formSubtitle,
  formTitle,
  isEventInPast,
  subtitle,
  title,
  willBeRecorded,
}) {
  const eventTitle = getEventTitle({
    eventType,
    isDatePast: isEventInPast,
    willBeRecorded,
    title,
  });

  const eventButtonText = getButtonText({
    buttonText,
    isDatePast: isEventInPast,
    willBeRecorded,
  });

  const subtitleText = getSubtitleText({
    date,
    eventType,
    isDatePast: isEventInPast,
    subtitle,
    willBeRecorded,
  });

  const formTitleText = getFormTitle({
    formTitle,
    isDatePast: isEventInPast,
    willBeRecorded,
  });

  const formSubtitleText = getFormSubtitle({
    formSubtitle,
    isDatePast: isEventInPast,
    willBeRecorded,
  });

  return {
    eventButtonText,
    eventTitle,
    formSubtitleText,
    formTitleText,
    subtitleText,
  };
}
