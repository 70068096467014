import React from "react";
import PropTypes from "prop-types";
import SbEditable from "storyblok-react";
import Card from "~components/atoms/card/card";
import Heading from "~components/atoms/heading/heading";
import Image from "~components/atoms/image/image";
import Avatar from "~components/molecules/avatar/avatar";
import { MEDIA } from "../../../../../prop_types";
import {
  imageWrapperStyle,
  profilePictureStyle,
  rightContentStyle,
  textWrapperStyle,
  wrapperStyle,
} from "./featured_person_landscape.module.scss";

export default function FeaturedPersonLandscape({
  name,
  jobTitle,
  profilePicture,
  logo,
  logoWidth,
  ...rest
}) {
  return (
    <Card isFullWidth stretch>
      <SbEditable content={rest}>
        <div data-testid="featured-person-landscape" className={wrapperStyle}>
          {profilePicture && (
            <div className={profilePictureStyle}>
              <Avatar width="80" image={profilePicture} />
            </div>
          )}
          <div className={rightContentStyle}>
            <div className={textWrapperStyle}>
              {name && (
                <Heading seoLevel="4" visualLevel={5}>
                  {name}
                </Heading>
              )}
              {jobTitle && <>{jobTitle}</>}
            </div>
            <div className={imageWrapperStyle}>
              {logo && <Image image={logo} width={logoWidth} />}
            </div>
          </div>
        </div>
      </SbEditable>
    </Card>
  );
}

FeaturedPersonLandscape.propTypes = {
  name: PropTypes.string,
  jobTitle: PropTypes.string,
  profilePicture: MEDIA,
  logo: MEDIA,
  logoWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

FeaturedPersonLandscape.defaultProps = {
  name: "Francesco Simoneschi",
  jobTitle: "CEO",
  profilePicture: null,
  logo: null,
  logoWidth: 128,
};
