import formatDate from "~utils/date_utils/format_date/format_date";

export default function getSubtitleText({
  date,
  eventType,
  isDatePast,
  subtitle,
  willBeRecorded,
}) {
  const formattedDate = date && formatDate(date);

  const lowerCaseEventType = eventType?.toLowerCase();

  if (isDatePast && date && willBeRecorded) {
    return `This ${lowerCaseEventType} was hosted on ${formattedDate}, and the recording is now available on-demand.`;
  }
  if (!isDatePast) {
    return subtitle;
  }

  return subtitle;
}
