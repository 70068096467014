/* eslint-disable import/prefer-default-export */
export const createAnchorLink = ({ path, anchor }) => {
  return {
    linktype: "story",
    story: {
      url: path,
    },
    anchor,
  };
};
