import React, { useMemo } from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import slugify from "slugify";
import Contain from "~components/atoms/contain/contain";
import RichText from "~components/molecules/rich_text/rich_text";
import RscHbCtaLink from "~components/molecules/rsc_hb_cta/rsc_hb_cta_link/rsc_hb_cta_link";
import RscHbInPageContactForm from "~components/molecules/rsc_hb_in_page_contact_form/rsc_hb_in_page_contact_form";
import RscHbPageHeader from "~components/molecules/rsc_hb_page_header/rsc_hb_page_header";
import FeatureGrid from "~components/organisms/feature_grid/feature_grid";
import FeaturedPeopleGrid from "~components/organisms/featured_people_grid/featured_people_grid";
import isDatePast from "~utils/date_utils/is_date_past/is_date_past";
import { createAnchorLink } from "~utils/link_utils/createAnchorLink/createAnchorLink";
import usePageData from "~hooks/use_page_data/use_page_data";
import EventDate from "../../components/molecules/event_date/event_date";
import { STORYBLOK_ENTRY } from "../../prop_types";
import TemplateGlobalEntry from "../template_global_entry/template_global_entry";
import getLandingPageStrings from "./helper_functions/get_landing_page_strings/get_landing_page_strings";
import {
  textContainerStyle,
  wrapperStyle,
} from "./template_landing_page_event.module.scss";

export default function TemplateLandingPageEvent(props) {
  const { story, pageContext } = usePageData(props);

  const { subNavData, lang, pagePath, breadcrumbsItems } = pageContext;
  const {
    buttonText,
    date,
    description: descriptionBlokArray,
    eventType,
    featuredPeopleGrid: featuredPeopleGridArray,
    featureGrid: featureGridBlokArray,
    formId,
    formSubtitle,
    formTitle,
    location,
    subtitle,
    title,
    willBeRecorded,
  } = story;

  const [featuredPeopleGrid] = featuredPeopleGridArray || [];
  const [featureGrid] = featureGridBlokArray || [];
  const [description] = descriptionBlokArray || [];

  const isEventInPast = isDatePast(date);

  const formLabel = slugify(title);

  let path = "/events/";
  let queryString = "";

  const {
    eventTitle,
    eventButtonText,
    subtitleText,
    formTitleText,
    formSubtitleText,
  } = useMemo(() => {
    return getLandingPageStrings({
      eventType,
      isEventInPast,
      willBeRecorded,
      buttonText,
      subtitle,
      title,
      date,
      formTitle,
      formSubtitle,
    });
  }, [
    buttonText,
    date,
    eventType,
    formSubtitle,
    formTitle,
    isEventInPast,
    subtitle,
    title,
    willBeRecorded,
  ]);

  if (pagePath && typeof window !== "undefined") {
    path = pagePath;
    path += path.endsWith("/") ? "" : "/";
    queryString = window.location.search;
    path = `${path}${queryString}`;
  }

  const anchorLink =
    typeof window !== "undefined" && createAnchorLink({ path, anchor: `form` });

  return (
    <TemplateGlobalEntry headerColor="blue" subNavData={subNavData} {...props}>
      <Contain small>
        <div className={wrapperStyle}>
          <RscHbPageHeader
            breadcrumbsItems={breadcrumbsItems}
            title={eventTitle}
            description={subtitleText}
          />

          <EventDate {...{ date, location, iconSize: 40 }} />
          <div className={textContainerStyle}>
            <RichText {...description} />
          </div>

          {featureGrid && <FeatureGrid {...featureGrid} />}
          <RscHbCtaLink
            theme="blue"
            title={formTitleText}
            text={formSubtitleText}
            link={anchorLink}
            buttonText={eventButtonText}
          />
          {featuredPeopleGrid && <FeaturedPeopleGrid {...featuredPeopleGrid} />}
        </div>
      </Contain>
      <RscHbInPageContactForm
        formId={formId}
        formLabel={formLabel}
        title={formTitleText}
        subtitle={formSubtitleText}
        lang={lang}
      />
    </TemplateGlobalEntry>
  );
}

TemplateLandingPageEvent.propTypes = {
  data: PropTypes.shape({
    story: STORYBLOK_ENTRY,
  }).isRequired,
  pageContext: PropTypes.shape({
    lang: PropTypes.string,
    subNavData: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

TemplateLandingPageEvent.defaultProps = {
  pageContext: {
    lang: "en-gb",
    subNavData: [],
  },
};

// TODO: get page specific metadata here
export const query = graphql`
  fragment TemplateLandingPageEventStory on Query {
    story: storyblokEntry(id: { eq: $id }) {
      assets {
        ...asset
      }
      content
      full_slug
      group_id
      alternates {
        full_slug
      }
    }
  }

  query eventLandingPageQuery($id: String, $langRegex: String) {
    ...TemplateLandingPageEventStory
    ...global
  }
`;
