export default function getFormTitle({
  isDatePast,
  willBeRecorded,
  formTitle,
}) {
  if (isDatePast && willBeRecorded) {
    return "Get access to the recording";
  }
  return formTitle;
}
