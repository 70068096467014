export default function getGridSplit({ length }) {
  let sSplit = length;

  if (length >= 5) {
    sSplit = 2;
  }
  if (length > 6 && length % 3 === 0) {
    sSplit = 3;
  }
  if (length > 6 && length % 2 === 0) {
    sSplit = 3;
  }
  if (length === 6) {
    sSplit = 3;
  }

  return { XXL: "2", S: sSplit.toString(), XS: "2", XXS: "1" };
}
